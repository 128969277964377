/* Pour les navigateurs WebKit (Safari, Chrome, etc.) */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #2c2f3c;
}

::-webkit-scrollbar-thumb {
  background: #ff5454;
  border-radius: 10px;
}

/* Pour Internet Explorer */
body {
  scrollbar-base-color: #a054ff;
  scrollbar-3dlight-color: #2c2f3c;
  scrollbar-highlight-color: #2c2f3c;
  scrollbar-track-color: #2c2f3c;
  scrollbar-arrow-color: #a054ff;
  scrollbar-shadow-color: #2c2f3c;
}

/*  cartes intro     */
.homepage-root .diagonal-clip {
  position: relative;
}

.homepage-root .diagonal-clip::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 30px solid #915eff;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}
