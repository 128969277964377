/* Gradients personnalisés */
.homepage-root .bg-space-blue {
  background-image: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
}

.homepage-root .bg-space-purple {
  background-image: linear-gradient(135deg, #360033, #0b8793);
}

.homepage-root .bg-space-green {
  background-image: linear-gradient(135deg, #134e5e, #71b280);
}

.homepage-root .bg-space-red {
  background-image: linear-gradient(135deg, #42275a, #734b6d);
}
.homepage-root .bg-pourpre-galactique {
  background-image: linear-gradient(135deg, #360033, #0b8793);
}

.homepage-root .bg-bleu-galactique {
  background-image: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
}
