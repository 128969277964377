@import url(./pre-custom-style.css);
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Post Custom Styles */

body {
  min-height: 100vh;
  font-family: var(--font-inter), sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  line-height: 1.66;
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-DMSans), sans-serif;
  letter-spacing: -2px;
}

h1 {
  font-size: 3rem;
  line-height: 1;
}

@media (min-width: 768px) {
  h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 100px;
  }
}

@media (min-width: 1400px) {
  h1 {
    font-size: 120px;
  }
}

h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

@media (min-width: 992px) {
  h2 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (min-width: 1200px) {
  h2 {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (min-width: 1400px) {
  h2 {
    font-size: 80px;
  }
}

h4 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  line-height: 1.33;
  letter-spacing: 0em;
}

h5 {
  font-size: 1.5rem;
  line-height: 2rem;
  line-height: 1.28;
}

@media (min-width: 992px) {
  h5 {
    font-size: 28px;
  }
}
